import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import OpenSeaDragonViewer from "../../../../composants/image/OpenSeaDragonViewer"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "winterSkiesZoomEN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function WinterSkiesEN(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Width: "10000", Height: "9941" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/winter-skies/tuiles/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360DegresEN"
    >
      <section className="section-conteneur">
        <p>
          A 225 mega pixels mosaic of the winter skies, taken in 2023 and 2024
          from both La Palma Island and from France. Experts will spot the many
          objects of the this fantastic region.
        </p>
      </section>
      <div id="galerie-image-interactive-texte-exifs">
        Canon EOS 6D Astrodon modded, Canon EF 135 mm F2 L set to F2.5, ISO
        1600. Mosaic of 42 images. Individual exposure time: 1 minute, total
        exposure time: about 31 hours.
      </div>
    </LayoutGalerieImageInteractive>
  )
}
